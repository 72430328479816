import { EmailLinkType, WorkflowAction, WorkflowActionType, WorkflowConditionType, MappingFunction, Operator, WorkflowConditionDefinition, WorkflowConditionTriggerType } from '@property-folders/contract/yjs-schema/entity-settings';
import { FormCode, planOptionsFull, SaleMethod, saleMethodOptions } from '@property-folders/contract';
import { FormTypes } from '../yjs-schema/property/form';
import { mapValues, pickBy } from 'lodash';

const AllowedOperators = {
  SingleField: [Operator.In, Operator.NotIn],
  List: [Operator.Includes, Operator.NotIncludes]
};

export enum StandardCondition {
  saleMethod= 'saleMethod',
  planType= 'planType',
  documentName = 'documentName',
  salesperson = 'salesperson',
}
const docs = mapValues(pickBy(FormTypes, f => !f.renderOpts?.processNotForm && !f.renderOpts?.notYetImplemented), f => f.shortLabel || f.label);

export const StandardConditions: Record<StandardCondition, WorkflowConditionDefinition> = {
  [StandardCondition.saleMethod]: { name: StandardCondition.saleMethod, type: WorkflowConditionType.Field, field: 'sale.saleMethod', label: 'Sale Method', options: saleMethodOptions, operators: AllowedOperators.SingleField, operator: Operator.In },
  [StandardCondition.planType]: { name: StandardCondition.planType, type: WorkflowConditionType.MappedField, mappingFn: MappingFunction.planTypes, label: 'Plan Type', options: planOptionsFull, operators: AllowedOperators.List, operator: Operator.Includes },
  [StandardCondition.documentName]: { name: StandardCondition.documentName, type: WorkflowConditionType.Document, label: 'Document Type', options: docs, operators: AllowedOperators.SingleField, operator: Operator.In },
  [StandardCondition.salesperson]: { name: StandardCondition.salesperson, type: WorkflowConditionType.MappedField, mappingFn: MappingFunction.salespersons, label: 'Salespeople', options: 'salespeople', operators: AllowedOperators.List, operator: Operator.Includes }
};

export const tokens = [
  { value: '@@PropertyAddress', label: 'Property Address', length: 45 },
  { value: '@@RecipientFirstName', label: 'Recipient First Name', length: 8 },
  { value: '@@RecipientFullLegalName', label: 'Recipient Full Name', length: 15 },
  { value: '@@SalespersonName', label: 'Salesperson Name', length: 15 },
  { value: '@@CoolingOffBeginsDate', label: 'Cooling Off Begins Date', length: 30 },
  { value: '@@CoolingOffEndsDate', label: 'Cooling Off Ends Date', length: 30 }
];

export type WorkflowTriggerTemplateType = {
  label: string,
  description: string,
  condition: WorkflowConditionDefinition,
  actions: WorkflowAction[]
};

export const WorkflowTriggerTemplates: Record<WorkflowConditionTriggerType, WorkflowTriggerTemplateType> = {
  [WorkflowConditionTriggerType.CoolingOffBegun]: {
    label: 'Cooling off begins',
    description: 'Cooling off begins at the later of contract signing or Form 1 service.',
    condition: {
      type: WorkflowConditionType.And,
      conditions: [
        { ...StandardConditions[StandardCondition.saleMethod], value: [SaleMethod.Auction] },
        { ...StandardConditions[StandardCondition.planType], value: ['STRATA PLAN', 'PRIMARY COMMUNITY PLAN'] }
      ]
    },
    actions: [{
      type: WorkflowActionType.Email,
      cta: {
        enabled: true,
        button1: { type: EmailLinkType.Document, text: 'Download Contract', formFamily: FormCode.RSC_ContractOfSale },
        button2: { type: EmailLinkType.Document, text: 'Download Form 1', formFamily: FormCode.Form1 }
      },
      to: ['purchaser']
    }]
  },
  [WorkflowConditionTriggerType.DocumentDistributed]: {
    label: 'Document is distributed',
    description: 'A document is distributed once all parties have signed and received a copy of the fully signed document.',
    condition: {
      type: WorkflowConditionType.And,
      conditions: [
        { ...StandardConditions[StandardCondition.documentName], value: [FormCode.RSAA_SalesAgencyAgreement] }
      ]
    },
    actions: [{
      type: WorkflowActionType.Email,
      cta: {
        enabled: true,
        button1: { type: EmailLinkType.Document, text: 'Download Document' }
      },
      to: ['vendor']
    }]
  }
};
