import { useState, useContext, useMemo, useEffect } from 'react';
import { Predicate } from '@property-folders/common/predicate';
import { minimumAdvertisingPrice } from '@property-folders/common/util/defaults';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { advertRangeOptions } from '@property-folders/common/data-and-text/constants';
import { canonicalisers, parseInt2 } from '@property-folders/common/util/formatting';
import '../Form.scss';
import { CollectionEditor } from './CollectionEditor';
import { WrField } from './CommonComponentWrappers';
import { CompareSaleInput } from './CompareSaleInput';
import clsJn from '@property-folders/common/util/classNameJoin';
import { composeErrorPathClassName } from '@property-folders/common/util/formatting';
import { WizardDisplayContext } from '../../context/WizardContexts';
import { ShowGuidanceNotesButton } from '../guidance/ShowGuidanceNotesButton';
import { SectionReplacementAnnexureV2 } from '../../display/form/SectionReplacementAnnexureV2';
import { FormCode, InstanceHistory, MaterialisedPropertyData, SaleDetailsType, SaleMethod, UploadType } from '@property-folders/contract';
import { usePropertyGnafLocation } from '../../hooks/usePropertyGnafLocation';
import { useCurrentEntity } from '../../hooks/useEntity';
import { FormUserInteractionContext } from '../../context/FormUserInteractionContext';
import { LineageContext } from '../../hooks/useVariation';
import { buildSigningTimelines } from '@property-folders/common/util/dataExtract';
import { useDispatch } from 'react-redux';
import { showNotes } from '@property-folders/common/redux-reducers/guidanceNotes';
import { LinkButton } from '../../display/LinkButton';
import { Alert } from 'react-bootstrap';
import { SetForceAccordionOpenFn } from 'dragged-components/Wizard/WizardStepPage';

export const determineIfAuctionHasBeenBreached = (
  saleMethod: SaleDetailsType['saleMethod'],
  variationsMode: boolean | undefined,
  snapshotHistory: InstanceHistory | undefined,
  vendorSalePrc: SaleDetailsType['vendorPrc']
) => () => {
  if (saleMethod !== SaleMethod.Auction) return {};
  if (!(variationsMode && snapshotHistory)) return {};
  const instanceGroupings = buildSigningTimelines(snapshotHistory).instanceSets;
  if (!instanceGroupings.length) return {};
  // So because agents may be correcting a bad vendor minimum price (say by dropping a zero), we can't flag
  // at every variation just because that exists in the price history. As such, we're going to look at the
  // most recent auction setting, and check our price only against that one. We're assuming that the legals
  // of the most recently signed auction is legal, and through it, so will this one

  // So let's start by extracting all the snapshots
  // These should be in ascending order (per a comment in buildAllSigningTimelines in @dataExtract.ts)
  const currentInstanceSnapshots = instanceGroupings[instanceGroupings.length-1].map(inst => snapshotHistory.data[inst.signing?.session?.associatedFiles?.propertyDataSnapshot?.id||'']);
  // Then we filter those for only auctions
  const inOrderAuctionSnapshots = currentInstanceSnapshots.filter(s=>s.sale?.saleMethod === SaleMethod.Auction);

  if (!inOrderAuctionSnapshots.length) return {};
  const latestAuctionSnapshot = inOrderAuctionSnapshots[inOrderAuctionSnapshots.length-1];
  const latestVendorPrice = latestAuctionSnapshot?.sale?.vendorPrc;
  const auctionPriceRestrictionBreached = parseInt2(vendorSalePrc??0) > parseInt2(latestVendorPrice??Number.MAX_SAFE_INTEGER);
  const prettyPreviousPrice = canonicalisers.audWithNegative(latestVendorPrice??0).display;

  return {
    auctionPriceRestrictionBreached,
    prettyPreviousPrice
  };
};

export const RSAASaleInput = ({ setForceAccordionOpen, ...restProps }: TransactionConsumerProps & { setHeaderActions?: () => void, setForceAccordionOpen?: SetForceAccordionOpenFn }): JSX.Element => {
  const dispatch = useDispatch();
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ parentPath: restProps.parentPath, myPath: '' });
  const { focusErrList } = useContext(WizardDisplayContext);
  const shouldShowFullValidationError = useContext(FormUserInteractionContext).userShouldSeeAllValidation;
  const localEntity = useCurrentEntity();
  const { variationsMode, snapshotHistory } = useContext(LineageContext);
  const comparableErrorFocusTarget = composeErrorPathClassName(['compareAddrs'], 'comparable-sales-subsection');
  const comparableErrorFocusList = composeErrorPathClassName(['compareAddrs'], undefined);
  const showComparableSectionError = shouldShowFullValidationError && (
    focusErrList.includes(comparableErrorFocusTarget)
    || focusErrList.includes(comparableErrorFocusList)
  );

  // if we have a gnaf id for an address, use that for the centre of the search
  const gnafCentre = usePropertyGnafLocation();
  const saleMap = transRoot?.sale;
  const [ showAdvice, setShowAdvice ] = useState<boolean|string>(false);

  const pmap = typeof saleMap?.vendorPrc === 'number' && typeof saleMap?.agentEstPrc === 'number' && Math.max(saleMap?.vendorPrc, saleMap?.agentEstPrc);
  const saleMethod = saleMap?.saleMethod;
  const vendorSalePrc = saleMap?.vendorPrc;
  const { auctionPriceRestrictionBreached, prettyPreviousPrice } = useMemo(
    determineIfAuctionHasBeenBreached(saleMethod, variationsMode, snapshotHistory, vendorSalePrc),
    [saleMethod, variationsMode, snapshotHistory, vendorSalePrc]
  );

  useEffect(()=>{
    setForceAccordionOpen?.(!!auctionPriceRestrictionBreached, 'Warning');
  }, [auctionPriceRestrictionBreached]);

  const rangeDefaults = minimumAdvertisingPrice(saleMap);
  const lowerDefault = Predicate.isNotNullish(rangeDefaults?.lowerDefault) ? canonicalisers.aud(rangeDefaults?.lowerDefault).display : undefined;
  const upperDefault = Predicate.isNotNullish(rangeDefaults?.upperDefault) ? canonicalisers.aud(rangeDefaults?.upperDefault).display : undefined;

  const documentUploadedForComparable = !!transRoot?.comparableSales?.annexureRef?.['family_'+FormCode.RSAA_SalesAgencyAgreement];

  const handleShowGuidanceNote = () => {

    dispatch(showNotes({ noteId: 'vendorPrcAuctionIncrease5a' }));
  };

  return (
    <div className="d-flex w-100 gapped-row">
      <div className='w-100'>
        <div className='subsection scrollspy-target' data-focus-path="subsection-advert-price">
          <div className='fs-4 d-flex align-items-center mb-2'>Price <ShowGuidanceNotesButton noteId='agentEstPrc' /></div>
          <div className="d-flex w-100 flex-wrap">
            <div className='flex-grow-1'>
              <WrField.Control name='agentEstPrc' myPath='sale.agentEstPrc' label="Agent's Estimated Selling Price"
                placeholder="$999,999" />
            </div>

            <div className='flex-grow-1'>
              <WrField.Control name='vendorPrc' myPath='sale.vendorPrc' label="Vendor’s Selling Price"
                placeholder="$9,999,999" />
            </div>
          </div>
          {auctionPriceRestrictionBreached && <Alert variant='warning' className='mt-2'>
            <p>When a property is being sold at auction, it is not legal to increase the vendor's selling price for auction during the term of the agreement.</p>
            <p>A prior agreement has been signed for auction with the vendor's selling price at {prettyPreviousPrice}.</p>
            <p>Proceed to signing, only if this change is to correct an error.</p>
            <p>Refer to <LinkButton onClick={handleShowGuidanceNote}>guidance note</LinkButton>.</p>
          </Alert>}
          <div className="d-flex w-100 flex-wrap gapped-row">
            {pmap && 'Prescribed Minimum Advertising Price: ' + canonicalisers.aud(pmap.toString()).display}
          </div>
          <div className="d-flex w-100 flex-wrap gapped-row">
            <div style={{ width: '190px' }}><WrField.Select name='advertRange' myPath='sale.advertRange'
              options={advertRangeOptions} label='Range or set price' />
            </div>
            {saleMap?.advertRange !== 'noprice' && <div className='flex-grow-1'>
              <WrField.Control
                name='advertPrc'
                myPath='sale.advertPrc'
                label={saleMap?.advertRange === 'range' ? 'Advertised lower' : 'Advertised price'}
                valuePlaceholder={lowerDefault}
              />
            </div>}
            {saleMap?.advertRange === 'range' && <div className='flex-grow-1'>
              <WrField.Control
                name='advertPrcUpper'
                myPath='sale.advertPrcUpper'
                label="Advertised upper"
                valuePlaceholder={upperDefault}
              />
            </div>}
          </div>
        </div>

        <div className={clsJn('subsection scrollspy-target', comparableErrorFocusTarget)}
          data-focus-path="subsection-comparable-sales">

          <div className='fs-4 d-flex align-items-center'>Comparable sales <ShowGuidanceNotesButton noteId='comparableSales'/><sup className='fs-5' style={{ color: 'red' }}> *</sup></div>

          {showComparableSectionError && <div className='d-block invalid-feedback mb-3'>Agent to provide Vendor with details of comparable sales and any other information to support Agent's estimated price</div>}
          <div className='mt-1 d-flex flex-wrap'>
            {!localEntity?.requireComparableSales && <div className='mt-2 me-3'>
              <WrField.BoolCheck
                label='Already provided'
                name='disableCompareAlreadyProvided'
                myPath='disableCompareAlreadyProvided'
                overrideChecked={documentUploadedForComparable?false:undefined}
                disabled={documentUploadedForComparable}
              />
            </div>}
            {!transRoot?.disableCompareAlreadyProvided && <div className='mt-2 flex-grow-1'><SectionReplacementAnnexureV2
              parentPath='comparableSales'
              myPath='annexureRef'
              typeTitle='Comparable Sales'
              uploadType={UploadType.ComparableSales}
              texts={{
                dropOverlayLabel: 'Drop comparable sale here',
                fieldError: 'A comparable sale is required',
                selectLabel: 'Select or upload a comparable sale'
              }}
            /></div>}
          </div>
          {!(transRoot?.disableCompareAlreadyProvided || transRoot?.comparableSales?.annexureRef?.['family_'+FormCode.RSAA_SalesAgencyAgreement]) && <>

            <CollectionEditor
              level={2}
              autoAddNew={true}
              maximum={4}
              myPath={'compareAddrs'}
              childItemRenderer={CompareSaleInput}
              itemNoun='Previous Sale'
              restorationFieldDisplay='addrLine'
              addTooltip='Add properties which are similar enough to act as a price guide'
              childProps={{ gnafCentre }}
              requiredForAutoAddRelativePaths={['addrLine', 'soldPrc', 'soldDate']}
            />
          </>}
        </div>
      </div>
    </div>
  );
};
